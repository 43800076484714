const getUri = (slug, activeSite) => {
	if (slug === 'strona-glowna' || slug === 'home' || slug === 'startseite') {
		// eslint-disable-next-line no-nested-ternary
		return activeSite === 'en' ? 'en' : activeSite === 'de' ? 'de' : '';
	}
	// eslint-disable-next-line no-nested-ternary
	return activeSite === 'en' ? `en/${slug}` : activeSite === 'de' ? `de/${slug}` : slug;
};

export default getUri;
