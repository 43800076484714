import dayjs from 'dayjs';

require('dayjs/locale/pl');
require('dayjs/locale/en');
require('dayjs/locale/de');

const getDateInProperFormat = (activeSite, createdDate) => {
	if (activeSite === 'pl') {
		return dayjs(createdDate).locale('pl').format('D_MMM');
	} if (activeSite === 'de') {
		return dayjs(createdDate).locale('de').format('D_MMM');
	}
	return dayjs(createdDate).locale('en').format('D_MMM');
};

export default getDateInProperFormat;
